import React from 'react';
import classnames from 'classnames';

const logo = 'https://materialbank-eu-cdn.global.ssl.fastly.net/media/wysiwyg/dark-mblogo.png';

const PoweredBy = ({ containerClassName }) => {
  return (
    <div className={classnames('mbw-powered-by', containerClassName)}>
      <img src={logo} className="mbw-powered-by__icon" />
      <p>
        Powered by{' '}
        <span className="mbw-bold-text">Material Bank</span>
      </p>
    </div>
  );
};

export default PoweredBy;
